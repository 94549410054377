var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "cancel-filings-modal",
        title: "Filing Cancellation",
        size: "lg",
        "footer-class": "justify-content-center",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              !_vm.loading
                ? _c(
                    "b-button",
                    {
                      staticClass: "submit-btn-width",
                      attrs: {
                        disabled: _vm.cancelling || _vm.needSignature,
                        size: "md",
                        variant: "danger",
                        "aria-label": "cancel button",
                      },
                      on: { click: _vm.cancelItems },
                    },
                    [
                      _vm.cancelling
                        ? _c("b-spinner", { attrs: { small: "" } })
                        : _c("div", [
                            _vm._v(
                              "\n        " + _vm._s(_vm.cancelText) + "\n      "
                            ),
                          ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.loading ? _c("ct-centered-spinner") : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._t("body", function () {
        return [
          _vm.hasComplianceOrderItems
            ? _c("div", [
                _c("p", [
                  _vm._v(
                    "\n        Failure to timely file renewal reports for your entity can affect your business' legal standing\n        and could result in additional charges from the state.\n      "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "\n        " + _vm._s(_vm.complianceCancelText) + "\n      "
                  ),
                ]),
                _vm.hasComplianceServices
                  ? _c(
                      "div",
                      [
                        _c("p", [
                          _vm._v(
                            "\n          Cancel the filing service if you would also like to cancel all future filings and automatic payments for that jurisdiction.\n        "
                          ),
                        ]),
                        _c("ct-table", {
                          ref: "complianceServicesTable",
                          attrs: {
                            items: _vm.complianceServices,
                            fields: _vm.serviceFields,
                            "selectable-function": _vm.selectable,
                            selectable: true,
                            "select-mode": "multi",
                            "is-paginated": false,
                            loaded: !_vm.loading,
                          },
                          on: { "selection-changed": _vm.selectionEvent },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ]
      }),
      _vm.selectedServices.length > 0
        ? _c(
            "div",
            [
              _c("p", [
                _c("span", [
                  _c("strong", [_vm._v("Representative agrees;")]),
                  _vm._v(
                    "\n        that this is binding on the company(s), and that if someone has access to this account,\n        whomever agrees to cancel, has the proper authority.\n      "
                  ),
                ]),
              ]),
              _c("p", [
                _c("span", [
                  _c("strong", [_vm._v("Representative agrees;")]),
                  _vm._v(
                    "\n        that this is a civil agreement and waives any and all rights or claims of statutory damages\n        or tort claims.\n      "
                  ),
                ]),
              ]),
              _c("p", [
                _c("strong", [_vm._v("Representative agrees;")]),
                _vm._v(
                  "\n      to release any and all liability or duty we had or continue to have to complete any annual\n      or biennial compliance filings with the state.\n    "
                ),
              ]),
              _c("p", [
                _c("strong", [_vm._v("Representative agrees;")]),
                _vm._v(
                  "\n      that as of today, the company has cancelled compliance service offered by us.\n    "
                ),
              ]),
              _c("strong", [_vm._v("Representative")]),
              _c("p", { staticClass: "m-b-lg" }, [
                _c("i", [
                  _vm._v(
                    "\n        By typing your name, you are agreeing to the end of service agreement. You are agreeing this\n        is the equivalent of a traditional signature. This will cancel the selected services with\n        us.\n      "
                  ),
                ]),
              ]),
              _c(
                "b-form-group",
                [
                  _c("label", { staticClass: "label-lg" }, [
                    _vm._v("* Enter your name to agree"),
                  ]),
                  _c("b-form-input", {
                    staticClass: "form-control",
                    attrs: { type: "text", required: "" },
                    model: {
                      value: _vm.signature,
                      callback: function ($$v) {
                        _vm.signature = $$v
                      },
                      expression: "signature",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.selectedServices.length === 0
        ? _vm._t("default", function () {
            return [
              _c("p", [
                _vm._v("Are you sure you would like to cancel the filing?"),
              ]),
            ]
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }